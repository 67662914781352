import { Grid } from "@material-ui/core"
import { PageTitles } from "../../shared/constants"
import PageTitleDescription from "../Common/PageTitleDescription"
import * as React from "react"
import OurPrograms from "./OurPrograms"
import { mentoringReviews } from "../../shared/reviews-data"
import Praises from "../Praises"
import OurMethodology from "./OurMethodology"
import HowItWorks from "./HowItWorks"
import ProgramCosts from "./ProgramCost"
import FAQsSection from "./FAQsSection"
import ReadyToLearn from "./ReadyToLearn"

export const Mentoring = () => (
  <Grid container justify="center">
    <Grid item xs={12}>
      <PageTitleDescription title={PageTitles.mentoring.title} description={PageTitles.mentoring.description}/>
    </Grid>
    <Grid item xs={12}>
      <OurPrograms/>
    </Grid>
    <Grid item xs={12}>
      <Praises data={mentoringReviews} auto={false}/>
    </Grid>
    <Grid item xs={12}>
      <OurMethodology/>
    </Grid>
    <Grid item xs={12}>
      <HowItWorks/>
    </Grid>
    <Grid item xs={12}>
      <ProgramCosts/>
    </Grid>
    <Grid item xs={12}>
      <FAQsSection/>
    </Grid>
    <Grid item xs={12}>
      <ReadyToLearn/>
    </Grid>
  </Grid>
)
