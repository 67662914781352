import javascript from "../../imgs/mentoring/javascript.svg"
import reactjs from "../../imgs/mentoring/reactjs.svg"
import reactRedux from "../../imgs/mentoring/react-redux.svg"
import materialui from "../../imgs/mentoring/materialui.svg"
import nodejs from "../../imgs/mentoring/nodejs.svg"

interface Program {
  name: string;
  authors: string[];
  svg: string;
  url: string;
}

const JavaScript = {
  name: "Mastering JavaScript",
  authors: ["Deeksha Sharma", "Harit Himanshu"],
  svg: javascript,
  url: '/mastering-javascript'
}

const ReactFundamentals = {
  name: "React Fundamentals",
  authors: ["Deeksha Sharma", "Harit Himanshu"],
  svg: reactjs,
  url: '/react-fundamentals'
}

const ReactRedux = {
  name: "Developing Apps using React & Redux",
  authors: ["Deeksha Sharma", "Harit Himanshu"],
  svg: reactRedux,
  url: '/react-redux'
}

const MaterialUI = {
  name: "Developing Beautiful Apps with Material-UI",
  authors: ["Deeksha Sharma", "Harit Himanshu"],
  svg: materialui,
  url: '/material-ui'
}

const FullStackJS = {
  name: "Developing fullstack apps with JavaScript & NodeJS",
  authors: ["Deeksha Sharma", "Harit Himanshu"],
  svg: nodejs,
  url: '/fullstack-javascript'
}

export const Programs: Program[] = [JavaScript, ReactFundamentals, ReactRedux, MaterialUI, FullStackJS]