import personalized from "../../imgs/mentoring/personalized.svg"
import sessions from "../../imgs/mentoring/sessions.svg"
import pair from "../../imgs/mentoring/pair.svg"
import doing from "../../imgs/mentoring/doing.svg"
import real from "../../imgs/mentoring/real.svg"
import fish from "../../imgs/mentoring/fish.svg"

interface Methodology {
  title: string,
  detail: string,
  svg: string
}

const Personalized = {
  title: "Personalized Plan",
  detail: "All our program offerings can be customized to meet your needs. We believe that no one size fits all. We embrace your uniqueness and assure you that you get the maximum benefit when you learn with us.",
  svg: personalized,
}

const Sessions = {
  title: "1:1 Sessions",
  detail: "All our sessions are delivered one-to-one online. We do not teach in a classroom setting where multiple learners come together in a single class.",
  svg: sessions,
}

const Pair = {
  title: "Pair Programming",
  detail: "Our sessions are full of visualizations to help you learn better, besides live pair programming with your mentor.",
  svg: pair,
}

const Doing = {
  title: "Learn by Doing",
  detail: "In each session, we combine conceptual knowledge with practical programming to help you apply what you learn.",
  svg: doing,
}

const RealWorld = {
  title: "Real-World Scenarios",
  detail: "We create meaningful problems that you would encounter while working on a project. This way, you learn to work not on an isolated problem but rather a problem in a project's context.",
  svg: real,
}

const Fish = {
  title: "Learn to Fish",
  detail: "We care about your overall learning. We give you guidance, tools, and methods that you help you in your programming career. We help to build the mindset of a problem-solver.",
  svg: fish,
}

export const Methodologies: Methodology[] = [Personalized, Sessions, Pair, Doing, RealWorld, Fish]