import * as React from "react"
import SectionP from "../Common/SectionP"
import SectionTitle from "../Common/SectionTitle"
import { Grid } from "@material-ui/core"
import { Programs } from "./programs"
import Card from "../Cards/Card"
import HoverCard from "../Cards/HoverCard"
import { navigate } from "gatsby"

const OurPrograms = (): JSX.Element => {
  return <SectionP>
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <SectionTitle color="textPrimary">OUR PROGRAMS</SectionTitle>
      </Grid>
      <Grid item xs={10}>
        <Grid container spacing={5}>
          {
            Programs.map(program => <Grid item sm={12} md={6} lg={4} key={program.name}>
                <HoverCard onClick={() => navigate(program.url)}>
                  <Card title={program.name}
                        photo={program.svg}/>
                </HoverCard>
              </Grid>,
            )}
        </Grid>
      </Grid>
    </Grid>
  </SectionP>
}

export default OurPrograms