import Layout from "../components/layout"
import SEO from "../components/SEO"
import { seoData } from "../shared/seoData"
import * as React from "react"
import { Mentoring } from "../components/Mentoring"

export default class MentoringContainer extends React.Component {
  render() {
    const seo = seoData.mentoringPage
    return (
      <>
        <SEO
          title={seo.title}
          description={seo.metaDescription}
          pathname={seo.pathname}
        />
        <Layout selectedTabValue={1}>
          <Mentoring/>
        </Layout>
      </>
    )
  }
}
