import SectionTitle from "../Common/SectionTitle"
import SectionP from "../Common/SectionP"
import * as React from "react"
import { Grid, makeStyles } from "@material-ui/core"
import { Methodologies } from "./methodology"
import Card from "../Cards/Card"

const OurMethodology = () => {
  const classes = useStyles()

  return <SectionP moreClasses={classes.section}>
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <SectionTitle color="textPrimary">OUR METHODOLOGY</SectionTitle>
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={7}>
          {
            Methodologies.map(methodology => <Grid item sm={12} md={6} lg={4} key={methodology.title}>
                <Card title={methodology.title}
                      photo={methodology.svg}
                      excerpt={methodology.detail}
                      alignCardItems={"center"}
                      alignExcerpt={"center"}
                      photoHeight={40}
                />
              </Grid>,
            )}
        </Grid>
      </Grid>
    </Grid>

  </SectionP>
}

const useStyles = makeStyles(theme => ({
  section: {
    position: "relative",
    backgroundColor: theme.palette.secondary["surface"],
  },
}))

export default OurMethodology